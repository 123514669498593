.notfound {
  font-size: 200px;
  color: #336dff;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.inicio{
  font-size: 100px;
}
