@import "variable";




@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
//Personalizar variables
// $body-bg: #000;


//cream refactor


// Font Family
$font-family: 'Poppins', sans-serif;

body {
  line-height: 1.35 !important;
  font-family: 'Poppins', sans-serif;
}

:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Poppins', serif;
}




$body-bg: #000;
$green: #70f04c;
// Import Bootstrap and its default variables


:root {
 
  --bg-left: #01579b;
  --bg-right: #1f2762;
  --bg-main: white;
}





.file{
  display:none;
  color:white;
  
  & + label{
    
    
    border-radius:5px;
    padding:8px 10px;
    font-family: Arial;
    font-size:12px;
    color:#fff;
    width:100%;
    height: 100;
    background:#28a745;
    text-align:center;
   
 
   
    &:after{
      background: green;
    }
    &:hover{
      background: green;
    }
    
  }
}




.spand{
  font-size:12px;
  position:absolute;
  padding-left: 30px;
  text-align:center;
  left:0;
  // background:#fff;
  color: black;
}
.dropdown-menu {
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    left: 0;
    right: auto;
  }
  @media screen and (min-width: $break-large) {
    right: 0;
    left: auto;
  }
}
li{
  margin-top: 10px;
}
ul {
  list-style: none;
  padding-left: 0;
}

.btnsearch {
  background-color: transparent;

  color: #ffffff;
  font-family: Arial;
}
.btn {
  border-top-width: 1px !important;
  // padding: 5px 10px !important;
  padding: 2.5px 7.5px !important;
}

table {
  table-layout: fixed;
  width: 250px;
  overflow-x: scroll;

  position: relative;
}



.header-fixed {
  position: fixed;
  top: 0px; display:none;
  background-color:white;
}

td {
  overflow-y: auto !important;
}

th {
  color: white !important;
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    width: 100px !important;
  }
  background-color: var(--bg-right) !important;
}




.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: var(--bg-right) !important;
  border-color: #4e555b !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: var(--bg-right) !important;
  border-color: #6c757d !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff !important;
  background-color: var(--bg-right) !important;
  border-color: #545b62 !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5) !important;
}

.table {
  width: auto !important;
}

.tableConfig{
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: 800px) {
    overflow-x: scroll !important;
   
    width: 700px !important;
  }
  @media screen and (min-width: $break-large) {
    
  }
}

.table {
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    overflow-x: scroll !important;
    display: block !important;
    width: 700px !important;
  }
  @media screen and (min-width: $break-large) {
    width: 100% !important;
  }
}

.env-input-file {
  height: auto !important;
}


.justify-content-center {
 
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    justify-content: auto !important;
  }
}

.center-pagination{
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
   
    justify-content: auto !important;
  }
  @media screen and (min-width: $break-large) {
    justify-content: center !important;
  }
}

.max-content {
  width: max-content;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}

.blue-50 {
  background: #E3F2FD;
}
.blue-100 {
  background: #BBDEFB;
}
.blue-200 {
  background: #90CAF9;
}
.blue-300 {
  background: #64B5F6;
}
.blue-400 {
  background: #42A5F5;
}
.blue-500 {
  background: #2196F3;
}
.blue-600 {
  background: #1E88E5;
}
.blue-700 {
  background: #1976D2;
}
.blue-800 {
  background: #1565C0;
}
.blue-900 {
  background: #0D47A1;
}
.blue-1000 {
  background: rgb(8, 52, 119);
  color: white;
}

.blue-1100 {
  background: #82B1FF;
}
.blue-1200 {
  background: #448AFF;
}
.blue-1400 {
  background: #6688ff;
}
.blue-1700 {
  background: #2962FF;
}

.blue-gray-50{
  background: #ECEFF1;
}
.blue-gray-100 {
  background: #CFD8DC;
}
.blue-gray-200 {
  background: #B0BEC5;
}
.blue-gray-300 {
  background: #90A4AE;
}
.blue-gray-400 {
  background: #78909C;
}
.blue-gray-500 {
  background: #607D8B;
}
.blue-gray-600 {
  background: #546E7A;
}
.blue-gray-700 {
  background: #455A64;
}
.blue-gray-800 {
  background: #37474F;
}
.blue-gray-900 {
  background: #263238;
}

.ant-table-footer {
  padding: 0px !important;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #1f2762 !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #1f2762 !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}